
import {
  defineComponent,
  ref,
  inject,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import fixedHeader from "@/components/common/Header.vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import { useRouter, useRoute } from "vue-router";


export default defineComponent({
  name: "Login",
//   components: {
//     fixedHeader,
//   },
  emits: ["loginsuccess"],
  setup(props, ctx) {
    // const hide = inject("handleHide");

    // function handleHide() {
    //   (hide as () => void)();
    // }
    const router = useRouter();
    const route = useRoute();
    const isActive = ref(0);
    function handleTab(type: number) {
      isActive.value = type;
    }

    const isweixin = ref(false);
    // const ua = navigator.userAgent.toLowerCase();
    // const reg = /MicroMessenger/i;
    // isweixin.value = reg.test(ua);

    //code 登录
    const userName = ref<string>("");
    const code = ref<string>("");
    const passCode = ref<string>("");
    const password = ref<string>("");
    const sendCodeFlag = ref<boolean>(true);
    const codeText = ref<string>("obtain");
    let timer: any = ref(null);
    const count = ref(60);

    function checkEmail(email: string) {
      if (email === "") {
        Toast({
          title: "Please fill in your email",
          type: "warning",
        });
        return false;
      }
      const reEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!reEmail.test(email)) {
        Toast({
          title: "Please enter the correct mailbox",
          type: "warning",
        });
        return false;
      }
      return true;
    }

    //获取验证码
    function getEmailCode() {
      if (!checkEmail(userName.value)) {
        return false;
      }
      if (!passCode.value) {
        Toast({
          // title: "请输入图形验证码",
          title: "Please enter the graphic verification code.",
          type: "warning",
        });
        return false;
      }
      if( !sendCodeFlag.value ){
         return false
      }
      sendCodeFlag.value = false;
      /**
       *  获取验证码
       *
       * */
      const formData = new FormData();
      formData.append("eml", userName.value);
      formData.append("option", "registerOpt");
      formData.append("passcode", passCode.value);
      api
        .post("/M/Server/GetEMLCode", formData)
        .then((res) => {
          if (res.data.success) {
            Toast({
              title: res.data.msg,
              type: "success",
            });
            count.value = 60;
            codeText.value = count.value + "s";
            timer = setInterval(function () {
              count.value--;
              if (count.value == 0) {
                clearInterval(timer);
                sendCodeFlag.value = true;
                codeText.value = "obtain";
              } else {
                codeText.value = count.value + "s";
              }

            }, 1000);
          } else {
            sendCodeFlag.value = true;
            Toast({
              title: res.data.msg,
              type: "error",
              duration:2000,
            });
          }
        })
        .catch(() => {
          Toast({
              title: 'Error in execution, please contact the administrator',
              type: "error"
          })
        });
    }

    //  登录成功处理
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }

    const codeImg = ref("/M/Server/GetVerifyCodeImg");

    function getCodeImg() {
      codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
    }

    //验证码登录
    function handleCodeLogin() {
      const formData = new FormData();
      formData.append("username", userName.value);
      formData.append("smscode", code.value);
      formData.append("passCode", passCode.value);
      api
        .post("/M/Server/DoLoginWidthCode", formData)
        .then((res) => {
          if (res.data.success) {
            updateToken(res.data.token);
            handleUserInfo(res.data.obj);
            //  清除购物车的guid
            //  localStorage.removeItem('guid')
            // Toast({
            //   title: "Login succeeded",
            //   type: "success",
            // });
            if (String(res.data.msg).trim() == "Please set the password") {
              Toast({
                title: "To set password",
                type: "success",
              });
              router.push({ name: "SetPassword" })
              sessionStorage.setItem("userName", userName.value)
              return false;
            } else {
              // handleHide();
              ctx.emit("loginsuccess");
            }
            const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            router.push(redirect)
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
        });
    }

    //密码登录
    function handlePassLogin() {
      const formData = new FormData();
      formData.append("username", userName.value);
      formData.append("password", password.value);
      api
        .post("/M/Server/DoLoginWidthPassword", formData)
        .then((res) => {
          if (res.data.success) {
            updateToken(res.data.token);
            handleUserInfo(res.data.obj);
             //  清除购物车的guid
            //  localStorage.removeItem('guid')
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                // handleHide();
                ctx.emit("loginsuccess");
              },
            });
            const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            router.push(redirect)
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
        });
    }

    function changeNum() {
      if(code.value.length > 6){
        code.value = code.value.slice(0, 6)
      }
    }

    return {
    //   handleHide,
      isActive,
      handleTab,
      isweixin,
      userName,
      code,
      password,
      codeText,
      getEmailCode,
      handleCodeLogin,
      handlePassLogin,
      changeNum,
      codeImg,
      getCodeImg,
      passCode
    };
  },
});
